import { useEffect } from "react";
import Typewriter from "typewriter-effect";
import { getCertificates } from "./certificate/helper/api_call";
import CommonComponent from "./commonsection/common";
import owner_pic from "./images/owner.png";

const HomeComponent = () => {
  return <CommonComponent>{<MiddleSection />}</CommonComponent>;
};

const MiddleSection = () => {

  useEffect(() => {
    getCertificates();
  }, []);


  return (
    <div className="container bg-home-section" align="center">
      <img
        src={owner_pic}
        alt="Sathi Das Profile Picture"
        data-aos="zoom-in"
        data-aos-duration="1000"
        data-aos-delay="1000"
        className="h-25 img-fluid"
      />
      <h3
        className="display-6 fw-bold home-page-my-name mt-2 aos-removal-class"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        Sathi Das
      </h3>
      <h5
        className="home-page-status aos-removal-class"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        Painting brings Inspiration
      </h5>
      <AutoTypingHelper />
    </div>
  );
};

const AutoTypingHelper = () => {
  return (
    <div
      className="auto-typing aos-removal-class"
      data-aos="zoom-in"
      data-aos-duration="2000"
      data-aos-delay="1000"
      style={{ color: "#000000" }}
    >
      <Typewriter
        options={{
          strings: [
            "Professional Artist From India",
            "Experience in Portrait Painting",
            "Still life painting makes me more attractive.",
            "Also Dedicatedly Teaching on Different Painting Techniques",
            "Hands Love Painting Water Colour Landscapes",
            "Creating content as Youtuber",
          ],
          autoStart: true,
          loop: true,
          delay: 40,
          deleteSpeed: 20,
          typeSpeed: 40,
        }}
      />
    </div>
  );
};

export default HomeComponent;
