import { useState } from "react";
import CommonComponent from "./commonsection/common";
import CustomSkeleton from "./helper/customskeleton";
import Masonry from "react-masonry-css";
import CommonNotFoundMessage from "./commonsection/loadingwithstyle/notaddedcontent";

const SkillSetComponent = () => {
  const [isLoading, setisLoading] = useState(false);

  const skillDataSet = [
    {
      name: "Still Life Painting",
      image:
        "https://i.pinimg.com/originals/e5/56/65/e55665c646438d5251143555eef19204.jpg",
    },
    {
      name: "Portrait Painting",
      image:
        "https://images.pexels.com/photos/7419327/pexels-photo-7419327.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Landscape Painting",
      image:
        "https://i.pinimg.com/originals/c8/93/be/c893be8b5b0b65aec8d3dbf077d9080a.jpg",
    },
    {
      name: "Acrylic Painting",
      image:
        "https://images.pexels.com/photos/4175054/pexels-photo-4175054.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Water Color Influence Painting",
      image:
        "https://images.pexels.com/photos/355288/pexels-photo-355288.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      name: "Figure Drawing",
      image:
        "https://images.pexels.com/photos/4752046/pexels-photo-4752046.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
  ];

  return (
    <CommonComponent isLoading={isLoading}>
      {(skillDataSet && (
        <SkillSetSection skillDataSet={skillDataSet} isLoading={isLoading} />
      )) || <CustomSkeleton />}
    </CommonComponent>
  );
};

const SkillSetSection = ({ skillDataSet, isLoading }) => {
  return (
    <div className="container mt-5">
      <h2
        className="text-center mb-5"
        data-aos="zoom-in"
        data-aos-duration="2000"
      >
        Skills
      </h2>
      <div className="row justify-content-center">
        <SkillContainer
          skillDataSet={skillDataSet ?? []}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

const SkillContainer = ({ skillDataSet, isLoading }) => {
  const defaultItemsGrid = () => (skillDataSet?.length > 0 ? 3 : 1);
  const midItemsGrid = () => (skillDataSet?.length > 0 ? 2 : 1);

  const breakpointColumnsObj = {
    default: defaultItemsGrid(),
    1100: midItemsGrid(),
    640: 1,
  };

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-masonry-grid"
      columnClassName="my-masonry-grid_column"
      align="center"
    >
      {(skillDataSet &&
        skillDataSet.length > 0 &&
        skillDataSet.map((skill, index) => {
          return (
            <SkillShowCaseComponent
              skillName={skill.name}
              skillPicture={skill.image}
              key={index}
            />
          );
        })) ||
        (isLoading ? (
          <CustomSkeleton />
        ) : (
          <CommonNotFoundMessage
            message={
              "Sathi not added any skill yet. Please visit after some days."
            }
          />
        ))}
    </Masonry>
  );
};

const SkillShowCaseComponent = ({ skillName, skillPicture }) => {
  return (
    <div
      className="card mb-5 mx-0 mx-md-3 skill-container aos-removal-class"
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay="500"
      style={{ borderRadius: "6px" }}
    >
      <img
        src={skillPicture}
        alt={`${skillName.toString().split(" ").join("-")}-skill-picture`}
        className="card-img-top skill-picture-section"
      />
      <div className="card-body skill-body">
        <h5 className="card-title fw-bold text-center">{skillName}</h5>
      </div>
    </div>
  );
};

export default SkillSetComponent;
