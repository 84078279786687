import Typewriter from "typewriter-effect";
import CommonComponent from "./commonsection/common";

const AboutComponent = () => {
  return (
    <CommonComponent>
      <AboutPage />
    </CommonComponent>
  );
};

const AboutPage = () => {
  return (
    <div className="container mt-3 about-page">
      <h2 className="text-center" data-aos="zoom-in" data-aos-duration="1000">
        About Myself
      </h2>
      <p
        className="mt-4 about-me"
        data-aos="fade-out"
        data-aos-duration="2000"
        data-aos-delay="800"
      >
        <Typewriter
          onInit={(typewriter) => {
            typewriter
              .typeString(
                `Myself <a href="https://www.facebook.com/Painting-Is-Life-101637685978046/" target="_blank">Sathi Das</a>, Professional Artist 
                from Kolkata, India. Passionately developing portrait painting along with giving life to the Landscapes 
                with watercolor.<br /><br />
  
                Love to transform reality object to still life painting. Experience in any types of Figure Drawing along with the best 
                possible look at the reality.
                Also creating content on YouTube about various medium.`
              )
              .start();
          }}
          options={{
            delay: 20,
            typeSpeed: 40,
          
          }}
        />
      </p>
    </div>
  );
};

export default AboutComponent;
