import Masonry from "react-masonry-css";
import IPadContainer from "../ipad-design/ipad-container";

const tutorialVideosCollection = [
  {
    embed_link: "https://www.youtube.com/embed/YJQrG5hXMkE",
    actual_link: "https://youtu.be/YJQrG5hXMkE",
  },
  {
    embed_link: "https://www.youtube.com/embed/X9z6zUKZJU8",
    actual_link: "https://youtu.be/X9z6zUKZJU8",
  },
  {
    embed_link: "https://www.youtube.com/embed/Zd5IOSfkhto",
    actual_link: "https://youtu.be/Zd5IOSfkhto",
  },
  
  
  {
    embed_link: "https://www.youtube.com/embed/-6fs89GedIU",
    actual_link: "https://youtu.be/-6fs89GedIU",
  },
  {
    embed_link: "https://www.youtube.com/embed/zMcvHp_wwZk",
    actual_link: "https://youtu.be/zMcvHp_wwZk",
  },
  {
    embed_link: "https://www.youtube.com/embed/f-zE0m3jNLM",
    actual_link: "https://youtu.be/f-zE0m3jNLM",
  },
];

const SocialMediaFlutterApp = () => {
  const defaultItemsGrid = () => (tutorialVideosCollection?.length > 0 ? 2 : 1);
  const midItemsGrid = () => (tutorialVideosCollection?.length > 0 ? 1 : 1);

  const breakpointColumnsObj = {
    default: defaultItemsGrid(),
    1300: midItemsGrid(),
    640: 1,
  };

  return (
    <div className="youtube_tutorial_videos container">
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
        align="center"
      >
        {tutorialVideosCollection.map((tutorialVideoData) => {
          return (
            <div
              className="mt-3 mb-5 aos-removal-class"
              data-aos="fade-up"
              data-aos-duration="1400"
              data-aos-delay="600"
            >
              <IPadContainer
                videoLink={tutorialVideoData.embed_link}
              />
            </div>
          );
        })}
      </Masonry>
    </div>
  );
};

const ChapterSublist = ({ redirectLink, chapterName }) => (
  <li
    style={{ lineHeight: "40px" }}
    data-aos="fade-left"
    data-aos-duration="1000"
    className="aos-removal-class"
  >
    <a href={redirectLink} target="_blank" rel="noopener noreferrer">
      {chapterName}
    </a>
  </li>
);

export default SocialMediaFlutterApp;
