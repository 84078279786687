import { API } from "../../helper/backend_api_root";

export const addDigitalArt = (digitalArtImage) => {
  return fetch(`${API}/digital_art/add`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ digitalArtImage }),
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
};

export const getAllDigitalArts = () => {
  return fetch(`${API}/digital_art/getall`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
};

export const deleteDigitalArt = (digital_art_id) => {
  return fetch(`${API}/digital_art/delete/${digital_art_id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  })
    .then((res) => res.json())
    .catch((err) => console.log(err));
};
